<template>
  <v-dialog
    width="50%"
    v-model="showDialog">
    <template
      v-if="!noActivator"
      v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon>mdi-label-multiple-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <label-selector
      @close="close"
      v-model="selectedLabels"
      ></label-selector>
  </v-dialog>
</template>

<script>
import LabelSelector from './LabelSelector.vue'

export default {
  name: 'NoteCategorySelectorDialog',

  components: {
    LabelSelector
  },

  props: {
    noActivator: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    selectedLabels: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      showDialog: this.show
    }
  },

  methods: {
    close() {
      this.showDialog = false
      this.$emit('close')
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue
      }
    }
  }

}
</script>