<template>
  <v-dialog
    v-model="showDialog"
    width="50%"
    >
    <template
      v-if="!noActivator"
      v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon>mdi-folder-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <note-folder-selector
      @close="close"
      @select-folder="$emit('select-folder', $event)"
      >
    </note-folder-selector>
  </v-dialog>
</template>

<script>
import NoteFolderSelector from './NoteFolderSelector.vue'

export default {
  name: 'NoteFolderSelectorDialog',

  components: {
    NoteFolderSelector
  },

  props: {
    noActivator: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showDialog: this.show
    }
  },

  methods: {
    close() {
      this.showDialog = false
      this.$emit('close')
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue
      }
    }
  }
}
</script>