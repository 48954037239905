<template>
  <v-dialog
    v-model="showDialog"
    width="50%"
    >
    <template
      v-if="!noActivator"
      v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon>mdi-menu-down-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar
        dense
        flat
        light>
        <v-subheader>Select note type to create</v-subheader>
        <v-spacer></v-spacer>
        <v-btn
          small
          icon
          class="mr-2"
          @click.stop.prevent="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <note-new-selector
          @select="select"
          >
        </note-new-selector>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NoteNewSelector from './NoteNewSelector.vue'

export default {
  name: 'NoteNewDialog',

  components: {
    NoteNewSelector
  },

  props: {
    noActivator: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showDialog: this.show
    }
  },

  methods: {
    select(type) {
      this.$emit('select', type)
      this.close()
    },

    close() {
      this.showDialog = false
      this.$emit('close')
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue
      }
    }
  }
}
</script>