<template>
  <draggable
    class="dragArea flex flex-col w-full shrink"
    tag="div"
    :list="subNodes"
    :group="{ name: 'g1' }"
    @move="onMoveNode">
    <div
      :class="['flex flex-row items-center cursor-pointer pr-2', activated ? 'bg-gray-200': 'hover:bg-gray-100']"
      :style="styles"
      >
      <div
        class="flex flex-row items-center shrink ml-3 rounded-full hover:bg-gray-200"
        @click.stop.prevent="onToggleCollapse">
        <template v-if="isContainer">
          <svg v-if="collapsed" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </template>
        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
        </template>
      </div>
      <div
        v-if="isCheckable"
        class="flex flex-row flex-shrink place-items-center">
        <input
          type="checkbox"
          class="w-4 h-4 text-gray-600 form-checkbox"
          v-model="selected">
      </div>
      <div
        class="flex flex-row items-center grow ml-1 text-left truncate"
        @click.stop.prevent="onActivate"
        @dblclick.stop.prevent="onToggleEdit">
        <input
          v-if="editing"
          class="block w-full form-input"
          placeholder="Type to input..."
          ref="input"
          autofocus
          @focus="onEditFocus"
          @blur="onCancelEdit"
          @keyup.esc="onCancelEdit"
          @keydown.enter="onDoneEdit"
          v-model="node[titleName]">
        <span v-else>{{ node[titleName] }}</span>
      </div>
      <div class="flex flex-row items-center shrink space-x-1">
        <slot name="item-append" v-bind="{ item: node, active: activated }">
        </slot>
      </div>
    </div>
    <!-- children -->
    <div v-show="!collapsed" class="flex flex-col block">
      <template v-for="(child, index) in subNodes">
        <tree-node
          :tree="tree"
          :key="index"
          :level="level+1"
          :node="child"
          :parent-selected="selected"
          :opened-nodes="openedNodes"
          :editing-node="editingNode">
          <template 
            v-for="(_, name) in $scopedSlots"
            v-slot:[name]="data">
            <slot
              :name="name"
              v-bind="data">
            </slot>
          </template>
        </tree-node>
      </template>
    </div>
  </draggable>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'

export default {
  name: 'TreeNode',

  components: {
    draggable
  },

  props: {
    tree: {
      type: Object,
      default: () => { return null }
    },
  
    node: {
      type: Object,
      default: () => { return null }
    },
    level: {
      type: Number,
      default: 0
    },
    titleName: {
      type: String,
      default: 'name'
    },
    childrenName: {
      type: String,
      default: 'children'
    },
    keyName: {
      type: String,
      default: 'key'
    },
    paddingStep: {
      type: Number,
      default: 18
    },
    parentSelected: {
      type: Boolean,
      default: false
    },
    openedNodes: {
      type: Array,
      default: () => {
        return []
      }
    },
    editingNode: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      collapsed: true,
      editing: false,
      activated: false,
      selected: false
    }
  },

  computed: {
    isContainer() {
      return typeof this.node[this.childrenName] == typeof []
    },

    subNodes() {
      return (this.node && this.node[this.childrenName]) || [];
    },

    isDraggable() {
      return true;
    },

    isCheckable() {
      return this.tree && this.tree.options.isNodeCheckable;
    },

    styles() {
      return {
        paddingLeft: `${this.level * this.paddingStep}px`,
        minHeight: '32px'
      }
    }
  },

  methods: {
    onToggleCollapse() {
      this.collapsed = !this.collapsed
      this.tree.onToggleCollapse(this.node, this)
    },

    onToggleEdit() {
      this.editing = true;
      this.tree.onStartEditingNode(this.node, this);
      this.$nextTick(() => {
        this.$refs.input.focus();
      })
    },

    onCancelEdit() {
      if (this.editing) {
        this.editing = false;
        this.tree.onCancelEditingNode(this.node);
      }
    },

    onDoneEdit() {
      this.editing = false;
      this.tree.onDoneEditingNode(this.node)
    },

    onActivate() {
      this.activated = true;
      this.tree.onActivateNode(this.node, this);
    },

    onEditFocus(event) {
      event.target.select();
    },

    onMoveNode(event) {
      console.log(event)
      return true
    }
  },

  watch: {
    parentSelected: function(newValue) {
      if (newValue) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },

    openedNodes: {
      deep: true,
      immediate: true,
      handler: function() {
        if (_.findIndex(this.openedNodes, (x) => x == this.node[this.keyName]) < 0) {
          if (!this.collapsed) {
            this.collapsed = true
          }
        } else {
          if (this.collapsed) {
            this.collapsed = false
          }
        }
      }
    },

    editingNode: function(newValue) {
      if (newValue && newValue.key == this.node.key) {
        this.onToggleEdit()
      }
    }
  },

  created() {
  }
}
</script>
