<template>
  <div class="w-full h-full flex flex-col text-sm">
    <div class="w-full flex flex-row items-center space-x-1">
      <v-subheader class="grow">Folder Navigation</v-subheader>
      <v-btn icon x-small @click.stop.prevent="onAddNote">
        <v-icon>mdi-note-plus-outline</v-icon>
      </v-btn>
      <v-btn icon x-small @click.stop.prevent="onAddFolder">
        <v-icon>mdi-folder-plus-outline</v-icon>
      </v-btn>
      <v-btn icon x-small @click.stop.prevent="collapseAll">
        <v-icon>mdi-collapse-all-outline</v-icon>
      </v-btn>
      <v-btn icon x-small @click.stop.prevent="refreshAll">
        <v-icon>mdi-folder-refresh-outline</v-icon>
      </v-btn>
    </div>
    <div class="flex flex-col overflow-auto" style="height: calc(100% - 48px);">
      <tree-view
        :nodes="folders"
        :opened-nodes="openedFolders"
        :editing-node="editingFolder"
        @activate-node="onActivateFolder"
        @cancel-edit="onCancelEditFolder"
        @done-edit="onDoneEditFolder"
        @move="onMoveFolder">
        <template v-slot:item-append="{item, active}">
          <template v-if="active">
            <v-menu
              close-on-click
              close-on-content-click
              offset-x
              right
              :min-width="300">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>  
                </v-btn>
              </template>
              <v-list dense>
                <template v-for="(menu, index) in folderMenuItems[ item.type ? 'others': 'folders']">
                  <v-list-item
                    v-if="menu.type == 'menu'"
                    dense
                    link
                    :key="menu.text"
                    @click="onFolderAction(item, menu.key)">
                    <v-list-item-icon class="mr-2">
                      <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ menu.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-else :key="index"></v-divider>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
      </tree-view>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import TreeView from '@/components/treeview'

export default {
  name: 'NoteFolderNavigator',

  components: {
    TreeView
  },

  data() {
    return {
      newFolderRecord: null,
      editingFolder: null,
      activeFolder: null,
      openedFolders: [],
      folderIcons: {
        all: 'mdi-note-multiple-outline',
        uncategoried: 'mdi-folder-hidden'
      },
      folderMenuItems: {
        folders: [
          { type: 'menu', text: 'Rename', icon: 'mdi-pencil-box-outline', key: 'rename' },
          { type: 'menu', text: 'Add New Sub Folder', icon: 'mdi-folder-plus-outline', key: 'subfolder' },
          { type: 'menu', text: 'Add New Note', icon: 'mdi-note-plus-outline', key: 'subnote' },
          { type: 'divider' },
          { type: 'menu', text: 'Remove Folder', icon: 'mdi-trash-can-outline', key: 'remove' }
        ],
        others: [
          { type: 'menu', text: 'Add New Note', icon: 'mdi-note-plus-outline', key: 'subnote' }
        ]
      },
      folders: [
        { key: 'all-notes', name: 'All Notes', type: 'all' },
        { key: 'uncategoried-notes', name: 'Uncategoried Notes', type: 'uncategoried' }
      ],
      foldersMap: {}
    }
  },

  computed: {
    items() {
      return this.folders
    }
  },

  methods: {
    /**
     * Folder action being triggerred. Different action
     * will be handled by different handler
     */
    onFolderAction(folder, action) {
      // four actions are supported, remove, rename, 
      // add sub folder, and add note
      let actions = {
        subnote: this.onAddNote,
        remove: this.removeFolder,
        rename: this.startEditFolder,
        subfolder: this.startAddFolder
      }

      if (actions[action]) {
        actions[action](folder)
      }
    },

    onActivateFolder(folder) {
      this.activeFolder = folder
      console.log(folder)
    },

    onAddFolder() {
      this.startAddFolder(this.activeFolder)
    },

    onCancelEditFolder() {
      console.log('now cancel edit')
      if (this.newFolderRecord) {
        let { parent, folder } = this.newFolderRecord
        if (!folder) {
          return
        }
        console.log('remove from parent temp')
        this.removeFromFolder(parent ? parent.key : null, folder.key)
      }
      this.newFolderRecord = null
      this.editingFolder = null
    },

    async onDoneEditFolder({ node }) {
      let localError = null
      if (this.newFolderRecord) {
        let { parent, folder } = this.newFolderRecord
        try {
          let folderObj = await this.$store.dispatch('folders/addFolder', {
            parent: parent ? parent.key : null,
            folder: folder
          })
          if (folderObj) {
            folder.key = folderObj.key
          } else {
            throw new Error('Folder not created due to error')
          }
        } catch(error) {
          console.error(error)
          localError = error
          this.removeFromFolder(parent ? parent.key : null, folder.key)
        }
      } else {
        try {
          await this.$store.dispatch('folders/updateFolder', {
            folder: node
          })
        } catch(error) {
          console.error(error)
          localError = error
        }
      }
      this.newFolderRecord = null
      this.editingFolder = null
      console.log('local error')
      console.log(localError)
      if (localError) {
        await this.$confirm(localError.message)
      }
    },

    async onMoveFolder() {
      console.log('move')
    },

    async removeFolder(folder) {
      if (!folder || !_.isObject(folder)) {
        return
      }

      try {
        let yes = await this.$confirm('Are you sure to remvoe the folder?')
        if (yes) {
          await this.$store.dispatch('folders/removeFolder', { folder: folder.key })
          this.removeFromFolder(folder.parent, folder.key)
          this.activeFolder = null
        }
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    onAddNote(folder) {
      let folderObj = !folder ? this.activeFolder : folder
      this.$emit('new-note', folderObj)
    },

    /**
     * make all folders collapsed
     */
    collapseAll() {
      this.openedFolders.splice(0, this.openedFolders.length)
    },

    startEditFolder(folder) {
      this.editingFolder = folder
    },

    startAddFolder(folder) {
      let addToRoot = false
      if (!folder) {
        addToRoot = true
      }

      let folderObj = null
      if (!addToRoot) {
        folderObj = _.isObject(folder) ? folder : _.get(this.foldersMap, folder)
        if (!folderObj) {
          return
        }
      }

      if (!addToRoot && _.findIndex(this.openedFolders, x => x == folderObj.key) < 0) {
        this.openedFolders.push(folderObj.key)
      }

      let newFolder = {
        key: `newfolder-${_.uniqueId()}`,
        name: 'New folder',
        children: []
      }
      let targetItems = addToRoot ? this.folders : folderObj.children
      targetItems.push(newFolder)

      this.newFolderRecord = {
        parent: folderObj,
        folder: newFolder
      }

      this.$nextTick(() => {
        this.editingFolder = newFolder
      })
    },

    removeFromFolder(parentKey, folderKey) {
      let targetItems = this.folders
      let parentObj = parentKey ? _.get(this.foldersMap, parentKey) : null
      if (parentObj) {
        targetItems = parentObj.children || []
      }
      let index = _.findIndex(targetItems, x => x.key == folderKey)
      if (index > -1) {
        targetItems.splice(index, 1)
      }

      this.clearFoldersMapRecord(folderKey)
    },

    addToFolder(parentKey, folderKey) {
      let targetItems = this.folders
      let parentObj = parentKey ? _.get(this.foldersMap, parentKey) : null
      if (parentObj) {
        targetItems = parentObj.children || []
      }

      let folderObj = _.get(this.foldersMap, folderKey)
      if (!folderObj) {
        return
      }

      let index = _.findIndex(targetItems, x => x.key == folderKey)
      if (index < 0) {
        targetItems.push( folderObj )
      }
    },
  
    clearFoldersMapRecord(folderKey) {
      let folderObj = _.get(this.foldersMap, folderKey)
      if (folderObj) {
        (folderObj.children || []).forEach(c => {
          this.clearFoldersMapRecord(c.key)
        })
        this.$delete(this.foldersMap, folderKey)
      }
    },

    refreshAll() {
      this.folders.splice(2, this.folders.length - 2)
      this.foldersMap = {}
      this.newFolderRecord = null
      this.editingFolder = null
      this.syncWithStore()
    },

    syncWithStore() {
      let items = this.$store.state.folders.items
      let refreshItem = (obj) => {
        let localObj = _.get(this.foldersMap, obj.key)
        if (!localObj) {
          localObj = {
            key: obj.key,
            name: obj.name,
            children: []
          }
          let parentKey = _.isObject(obj.parent) ? obj.parent.key: (_.isString(obj.parent) ? obj.parent : null) 
          this.foldersMap[obj.key] = localObj
          this.addToFolder(parentKey, localObj.key)
        } else {
          // existed already
          localObj.name = obj.name
        }

        if (obj.children) {
          localObj.children = obj.children.map(c => {
            return refreshItem(c)
          })
        }
        return localObj
      }

      items.forEach(item => {
        refreshItem(item)
      })
    }
  },

  async mounted() {
    await this.$store.dispatch('folders/load')
    this.syncWithStore()
  }
}
</script>