<template>
  <div class="flex flex-col w-full h-full">
    <div class="w-full ma-0 pl-0 pr-4">
      <div class="flex flex-row ma-0 pa-0 items-center">
        <v-btn
          icon
          small
          class="ml-3"
          @click="showFolders = !showFolders">
          <v-icon>mdi-file-tree-outline</v-icon>
        </v-btn>
        <template v-if="folders.length > 0">
          <v-breadcrumbs :items="folders" class="px-0 py-0 my-0">
            <template v-slot:item="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" class="mr-0 pr-0">
                    {{ item.text }}
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <template v-for="(opt, index) in item.options">
                    <v-list-item v-if="opt.type === 'menu'" dense :key="opt.value">
                      <v-icon>mdi-circle-outline</v-icon>
                      <span class="mx-1">{{ opt.label }}</span>
                    </v-list-item>
                    <v-divider v-else :key="index"></v-divider>
                  </template>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </template>
        <template v-else>
          <span class="text-gray-500 text-sm px-2">All notes</span>
        </template>
        <v-spacer></v-spacer>
        <div class="flex flex-row space-x-1 items-center pr-4" style="max-width: 40%">
          <span class="caption">Labels:</span>
          <label-list
            :labels="selectedLabels"
            @click-close="removeSelectedLabel"
            >
          </label-list>
          <label-selector-menu :selected-labels="selectedLabels"></label-selector-menu>
        </div>
        <v-spacer></v-spacer>
        <div class="flex flex-row space-x-1 items-center shrink" style="max-width: 40%">
          <span class="caption">Types:</span>
          <note-type-list
            :types="selectedTypes"
            @click-close="removeSelectedType"
            >
          </note-type-list>
          <note-type-selector-menu
            :selected-types="selectedTypes"
            >
          </note-type-selector-menu>
        </div>
      </div>
    </div>
    <div class="grow" style="height: calc(100% - 28px);">
      <split-pane
        split="vertical"
        :default-percent="showFolders ? 25 : 0"
        class="w-full h-full">
        <div class="w-full h-full overflow-hidden" slot="paneL">
          <note-folder-navigator
            @new-note="onShowNewNoteSelector">
          </note-folder-navigator>
        </div>
        <div class="py-4 w-full h-full overflow-y-auto" slot="paneR">
          <template v-if="viewMode == 'grid'">
            <notes-grid
              :notes="noteRecords"
              @remove="removeNote"
              @copy="cloneNote"
              @move="onShowNoteFolderSelector"
              @label="onShowNoteLabelSelector"
              @remove-label="onRemoveNoteLabel"
              @pin="onTogglePinNote"
              @select="onEditNote"
              >
            </notes-grid>
          </template>
          <template v-else>
            <notes-list
              :notes="noteRecords"
              @remove="removeNote"
              @copy="cloneNote"
              @move="onShowNoteFolderSelector"
              @label="onShowNoteLabelSelector"
              @remove-label="onRemoveNoteLabel"
              @pin="onTogglePinNote"
              @select="onEditNote"
              >
            </notes-list>
          </template>
          <div class="w-full flex items-center my-8">
            <v-btn
              v-if="hasMoreNotes"
              outlined
              class="mx-auto"
              @click.stop.prevent="loadMoreNotes(false)"
              >
              Click to load more...
            </v-btn>
            <p v-else class="mx-auto">No more data to load</p>
          </div>
        </div>
      </split-pane>
    </div>
    <note-folder-selector-dialog
      no-activator
      :show="showNoteFolderSelector"
      @close="showNoteFolderSelector = false"
      @select-folder="doneSelectNoteFolder"
      >
    </note-folder-selector-dialog>
    <label-selector-dialog
      no-activator
      :show="showLabelSelector"
      :selected-labels="selectedNoteLabels"
      @close="doneSelectNoteLabel">
    </label-selector-dialog>
    <note-new-dialog
      no-activator
      :show="showNoteNewSelector"
      @close="showNoteNewSelector = false"
      @select="onNewNote"
      >
    </note-new-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import NotesGrid from '@/components/NotesGrid.vue'
import NotesList from '@/components/NotesList.vue'
import LabelSelectorMenu from '@/components/LabelSelectorMenu.vue'
import NoteTypeSelectorMenu from '@/components/NoteTypeSelectorMenu.vue'
import NoteFolderNavigator from '@/components/NoteFolderNavigator.vue'
import MakeLoadMoreMixin from '@/api/make-loadmore-mixin'
import SplitPane from '@/components/splitpane'
import LabelSelectorDialog from '@/components/LabelSelectorDialog.vue'
import NoteFolderSelectorDialog from '@/components/NoteFolderSelectorDialog.vue'
import NoteNewDialog from '@/components/NoteNewDialog.vue'
import LabelList from '@/components/LabelList.vue'
import NoteTypeList from '@/components/NoteTypeList.vue'

export default {
  name: 'NotesView',

  components: {
    NotesGrid,
    NotesList,
    LabelSelectorMenu,
    NoteTypeSelectorMenu,
    NoteFolderNavigator,
    SplitPane,
    LabelSelectorDialog,
    NoteFolderSelectorDialog,
    NoteNewDialog,
    LabelList,
    NoteTypeList
  },

  mixins: [
    MakeLoadMoreMixin({ name: 'Note' }),
  ],

  data() {
    return {
      showFolders: true,
      showLabelSelector: false,
      showNoteFolderSelector: false,
      showNoteNewSelector: false,
      selectedFolder: null,
      selectedLabels: [],
      selectedTypes: [],
      activeNote: null,
      selectedNoteLabels: []
    }
  },

  computed: {
    viewMode() {
      return this.$store.state.notesViewMode
    },

    folders() {
      return []
    },

    noteIncludes() {
      return ['labels']
    },

    // used by load more mixin
    noteFilters() {
      let filters = []
      if (this.selectedLabels.length > 0) {
        filters.push( { op: 'containedIn', key: 'labels', value: this.selectedLabels } )
      }
      if (this.selectedTypes.length > 0) {
        let types = this.selectedTypes.map(t => t.key)
        filters.push( { op: 'containedIn', key: 'type', value: types})
      }
      if (this.selectedFolder) {
        if (this.selectedFolder.id) {
          filters.push({ op: 'equalTo', key: 'parent', value: this.selectedFolder.id })
        }
      }
      filters.push({
        op: 'equalTo',
        key: 'deletedAt',
        value: null
      })
      return filters
    },

    noteSorts() {
      return [
        { op: 'descending', keys: ['createdAt', 'updatedAt']}
      ]
    }
  },

  methods: {
    async removeNote(note) {
      let yes = await this.$confirm('Are you sure to remove the note?')
      if (yes) {
        try {
          await this.$Parse.Cloud.run('deleteNote', { note: note.id })
          await this.loadMoreNotes(true)
        } catch(error) {
          console.error(error)
        }
      }
    },

    async cloneNote(note) {
      let yes = await this.$confirm('Are you sure to clone this note?')
      if (yes) {
        console.log(note)
      }
    },

    async shareNote(note) {
      console.log(note)
    },

    removeSelectedLabel(label) {
      let index = _.findIndex(this.selectedLabels, (x) => x.id == label.id)
      if (index > -1) {
        this.selectedLabels.splice(index, 1)
      }
    },

    removeSelectedType(type) {
      let index = _.findIndex(this.selectedTypes, (x) => x.key == type.key)
      if (index > -1) {
        this.selectedTypes.splice(index, 1)
      }
    },

    onShowNoteLabelSelector(note) {
      this.showLabelSelector = true
      this.activeNote = note
      this.selectedNoteLabels.splice(0, this.selectedNoteLabels.length);
      (note.labels || []).forEach(label => {
        this.selectedNoteLabels.push(label)
      })
    },

    async doneSelectNoteLabel() {
      if (!this.activeNote) {
        return
      }
      let existedLabels = this.activeNote.labels
      let labels = _.filter(
        this.selectedNoteLabels, 
        (label) => {
          return _.findIndex(existedLabels, (innerLabel) => { label.id == innerLabel.id }) < 0
        })

      try {
        if (labels.length > 0) {
          await this.$Parse.Cloud.run('addNoteLabel', {
            note: this.activeNote.id,
            labels: labels.map(label => label.id)
          })

          await this.$Parse.Object.fetchAll([this.activeNote])
          await this.$Parse.Object.fetchAll(labels)
        }
      } catch(error) {
        console.error(error)
      }

      // clear
      this.showLabelSelector = false
      this.selectedNoteLabels.splice(0, this.selectedNoteLabels.length)
      this.activeNote = null
    },

    async onRemoveNoteLabel(note, label) {
      try {
        if (!note || !label) {
          return
        }

        let yes = await this.$confirm('Are you sure to remove the note\'s label?')
        if (!yes) {
          return
        }

        await this.$Parse.Cloud.run('deleteNoteLabel', {
          note: note.id,
          label: label.id
        })
        await this.$Parse.Object.fetchAll([note])
        await this.$Parse.Object.fetchAll([label])
      } catch(error) {
        console.error(error)
      }
    },

    onShowNoteFolderSelector(note) {
      this.activeNote = note
      this.showNoteFolderSelector = true
    },

    async doneSelectNoteFolder(folder) {
      if (this.activeNote && folder) {
        try {
          this.activeNote.set('folder', folder)
          await this.activeNote.save()
        } catch(error) {
          console.error(error)
        }
      }
      this.showNoteFolderSelector = false
      this.activeNote = null
    },

    onTogglePinNote(note) {
      this.$store.commit('togglePinNote', note)
    },

    onShowNewNoteSelector(folder) {
      console.log(folder)
      this.showNoteNewSelector = true
    },

    onNewNote(type) {
      console.log(type)
      console.log(this.selectedFolder)
      this.showNoteNewSelector = false
    },

    onEditNote(note) {
      this.$eventBus.$emit('edit.note', note)
    }
  },

  mounted() {
    if (!this.$api.isAuthenticated()) {
      this.$router.push({ name: 'login', query: { ref: 'user-notes' } })
    }

    this.$eventBus.$on('new.note', async () => {
      await this.loadMoreNotes(true)
    })

    this.$eventBus.$on('refresh', async () => {
      await this.loadMoreNotes(true)
    })
  },
}
</script>