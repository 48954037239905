<template>
  <div
    class="flex flex-col w-full h-full overflow-auto"
    @click.stop.prevent="onClearActivation">
    <template v-for="(node, index) in nodes">
      <tree-node
        :tree="tree"
        :key="index"
        :level="0"
        :childrenName="childrenName"
        :titleName="titleName"
        :node="node"
        :opened-nodes="localOpenedNodes"
        :editing-node="editingNode">
        <template 
          v-for="(_, name) in $scopedSlots"
          v-slot:[name]="data">
          <slot
            :name="name"
            v-bind="data">
          </slot>
        </template>
      </tree-node>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import TreeNode from './TreeNode.vue'

export default {
  name: 'TreeView',

  components: {
    TreeNode
  },

  props: {
    nodes: {
      type: Array,
      default: () => {
        return [];
      },
      required: true
    },
  
    options: {
      type: Object,
      default: () => {
        return {
          isNodeCheckable: false
        }
      }
    },
    
    titleName: {
      type: String,
      default: 'name'
    },
    
    childrenName: {
      type: String,
      default: 'children'
    },

    keyName: {
      type: String,
      default: 'key'
    },

    openedNodes: {
      type: Array,
      default: () => {
        return []
      }
    },
    
    editingNode: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      localOpenedNodes: this.openedNodes,
      localEditingNode: {
        node: null,
        vmNode: null
      },
      localActivatedNodes: [],
      multipleSelection: false
    }
  },

  computed: {
    tree() {
      return this;
    }
  },

  methods: {
    onStartEditingNode(node, vmNode) {
      let localEditingNode = this.localEditingNode.node;
      if (localEditingNode && localEditingNode.id !== node.id) {
        this.localEditingNode.vmNode.editing = false;
      }
      this.localEditingNode.node = node;
      this.localEditingNode.vmNode = vmNode;
      this.$emit('start-edit', { node, vmNode })
    },

    onCancelEditingNode() {
      this.clearEditingNode()
      this.$emit('cancel-edit')
    },

    onDoneEditingNode(node, value) {
      if (this.localEditingNode) {
        this.clearEditingNode();
      }
      this.$emit('done-edit', { node, value});
    },

    clearEditingNode() {
      if (this.localEditingNode.vmNode) {
        this.localEditingNode.vmNode.editing = false;
      }
      this.localEditingNode.node = null;
      this.localEditingNode.vmNode = null;
      this.$emit('clear-edit')
    },

    onActivateNode(node, vmNode) {
      if (!this.multipleSelection) {
        if (_.findIndex(this.localActivatedNodes, n => n.node[this.keyName] == node[this.keyName]) < 0) {
          this.clearActivatedNodes();
          this.localActivatedNodes.push({
            node,
            vmNode
          });
          this.clearEditingNode()
          this.$emit('activate-node', node)
        }
      }
    },

    onToggleCollapse(node, vmNode) {
      let index = _.findIndex(this.localOpenedNodes, x => x == node[this.keyName])
      if (vmNode.collapsed) {
        // collapsed, and existed in opened nodes, then remove it
        if (index > -1) {
          this.localOpenedNodes.splice(index, 1)
        }
      } else {
        // not collapsed, not existed in opened nodes, then add it
        if (index < 0) {
          this.localOpenedNodes.push(node[this.keyName])
        }
      }
    },

    onClearActivation() {
      this.clearActivatedNodes()
    },

    clearActivatedNodes() {
      this.localActivatedNodes.forEach(r => {
        if (r) {
          let { vmNode } = r;
          if (vmNode) {
            vmNode.activated = false;
          }
        }
      })
      this.localActivatedNodes = [];
      this.$emit('activate-node', null)
    }
  }
}
</script>
