<template>
	<div :class="classes" :style="styles">
		<slot></slot>
	</div>
</template>

<script>
  export default {
    name: 'Pane',
    props: {
      defaultMode: String,
      name: String,
      className: String
    },
    data() {
      const classes = [this.$parent.split, this.className]
      return {
        classes: classes.join(' '),
        percent: 50,
        split: this.$parent.split,
        mode: this.defaultMode
      }
    },

    computed: {
      styles() {
        let result = {};
        if (this.name === 'paneR' && (this.mode === 'fixedL' || this.mode === 'fixedB')) {
          if (this.split === 'vertical') {
            result = {
              left: `${this.$parent.defaultSizeL}px`
            }
          } else {
            result = {
              top: `${this.$parent.defaultSizeL}px` 
            }
          }
        }
        return result;
      }
    },

    watch: {
      defaultMode: function(value) {
        this.mode = value;
      }
    }
  }
</script>

<style scoped>

.splitter-pane.vertical.splitter-paneL {
  overflow: hidden;
  position: absolute;
  left: 0px;
  height: 100%;
  padding: 0px;
}
.splitter-pane.vertical.splitter-paneR {
  overflow: hidden;
  position: absolute;
  right: 0px;
  height: 100%;
  padding: 0px;
}

.splitter-pane.horizontal.splitter-paneL {
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
}
.splitter-pane.horizontal.splitter-paneR {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0;
}

</style>