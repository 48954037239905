<template>
	<div :class="classes" :style="styles"></div>
</template>

<script>
  export default {
    props: {
      defaultMode: String,
      split: {
        validator(value) {
          return ['vertical', 'horizontal'].indexOf(value) >= 0
        },
        required: true
      },
      className: String
    },

    data() {
      return {
        mode: this.defaultMode
      }
    },

    computed: {
      classes() {
        const classes = ['splitter-pane-resizer', this.split, this.className]
        return classes.join(' ')
      },

      styles() {
        if (['fixedL', 'fixedR', 'fixedB'].indexOf(this.mode) > -1) {
          return {
            display: 'none'
          };
        } else {
          return {};
        }
      }
    },

    watch: {
      defaultMode(value) {
        this.mode = value;
      }
    }
  }
</script>

<style scoped>

.splitter-pane-resizer {
  -moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0);
	position: absolute;
	z-index: 1;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}
.splitter-pane-resizer:hover {
  background: #999;
  opacity: .2;
}

.splitter-pane-resizer.horizontal {
  height: 4px;
  border: none;
	cursor: row-resize;
	width: 100%;
}
.splitter-pane-resizer.vertical {
  width: 4px;
	height: 100%;
  border: none;
	cursor: col-resize;
}

</style>