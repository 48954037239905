<template>
<div 
  :style="{ cursor, userSelect}" 
  class="vue-splitter-container clearfix" 
  @mouseup="onMouseUp" 
  @mousemove="onMouseMove">
  <pane 
    class="splitter-pane splitter-paneL"
    :defaultMode="mode"
    :name="'paneL'" 
    :split="split" 
    :style="leftPane">
    <slot name="paneL"></slot>
  </pane>
  <resizer
    :defaultMode="mode"
    :className="className" 
    :style="{ [resizeType]: percent+'%'}" 
    :split="split" 
    @mousedown.native="onMouseDown" 
    @click.native="onClick">
  </resizer>
  <pane 
    class="splitter-pane splitter-paneR" 
    :defaultMode="mode"
    :name="'paneR'"
    :split="split" 
    :style="rightPane">
    <slot name="paneR"></slot>
  </pane>
</div>
</template>

<script>
import Resizer from './resizer.vue'
import Pane from './pane.vue'

export default {
  name: 'splitPane',
  components: {
    Resizer,
    Pane
  },

  props: {
    // fixed or percent
    defaultMode: {
      type: String,
      default: 'percent',
      validator: function (value) {
        // The value must match one of these strings
        return ['fixedL', 'fixedR', 'fixedB', 'percent'].indexOf(value) !== -1
      }
    },

    // size in pixels
    defaultSizeL: {
      type: Number,
      default: 24
    },

    // size in pixels
    defaultSizeR: {
      type: Number,
      default: 24
    },

    // width in percents
    minPercent: {
      type: Number,
      default: 10
    },

    defaultPercent: {
      type: Number,
      default: 50
    },

    // perform validation on split mode
    split: {
      validator(value) {
        return ['vertical', 'horizontal'].indexOf(value) >= 0
      },
      required: true
    },
    className: String
  },

  computed: {
    userSelect() {
      return this.active ? 'none' : ''
    },

    cursor() {
      return this.active ? 'col-resize' : ''
    },

    leftPane() {
      let result = {};
      let type = this.type;
      switch(this.mode) {
      case 'fixedL':
        result = { [type]: this.sizeL + 'px' };
        break;
      
      case 'fixedR':
        result = { [type]: `calc(100% - ${this.sizeR}px)` };
        break;

      case 'fixedB':
        result = { [type]: this.sizeL + 'px' };
        break;

      default:
        result = { [type]: this.percent + '%' };
        break;
      }
      return result;
    },

    rightPane() {
      let result = {};
      let type = this.type;
      switch(this.mode) {
      case 'fixedR':
        result = { [type]: this.sizeR + 'px' };
        break;
      
      case 'fixedL':
        result = { [type]: `calc(100% - ${this.sizeL}px)` };
        break;

      case 'fixedB':
        result = { [type]: this.sizeR + 'px' };
        break;
      
      default:
        result = { [type]: 100 - this.percent + '%' };
        break;
      }
      return result;
    }
  },

  data() {
    return {
      active: false,
      hasMoved: false,
      height: null,
      mode: this.defaultMode,
      sizeL: this.defaultSizeL,
      sizeR: this.defaultSizeR,
      percent: this.defaultPercent,
      type: this.split === 'vertical' ? 'width' : 'height',
      resizeType: this.split === 'vertical' ? 'left' : 'top'
    }
  },

  methods: {
    onClick() {
      if (!this.hasMoved) {
        this.percent = 50
        this.$emit('resize')
      }
    },

    onMouseDown() {
      this.active = true
      this.hasMoved = false
    },

    onMouseUp() {
      this.active = false
    },

    onMouseMove(e) {
      if (['fixedL', 'fixedR', 'fixedB'].indexOf(this.mode) >= 0) {
        return;
      }

      if (e.buttons === 0 || e.which === 0) {
        this.active = false
      }
      if (this.active) {
        let offset = 0
        let target = e.currentTarget
        if (this.split === 'vertical') {
          while (target) {
            offset += target.offsetLeft
            target = target.offsetParent
          }
        } else {
          while (target) {
            offset += target.offsetTop
            target = target.offsetParent
          }
        }
        const currentPage = this.split === 'vertical' ? e.pageX : e.pageY
        const targetOffset = this.split === 'vertical' ? e.currentTarget.offsetWidth : e.currentTarget.offsetHeight
        const percent = Math.floor(((currentPage - offset) / targetOffset) * 10000) / 100
        if (percent > this.minPercent && percent < 100 - this.minPercent) {
          this.percent = percent
        }
        this.$emit('resize')
        this.hasMoved = true
      }
    }
  },

  watch: {
    defaultPercent: function (value) {
      this.percent = value;
    },

    defaultSizeL: function(value) {
      this.sizeL = value;
    },

    defaultSizeR: function(value) {
      this.sizeR = value;
    },

    defaultMode: function(value) {
      this.mode = value;
    }
  }
}
</script>

<style scoped>

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.vue-splitter-container {
  height: 100%;
  position: relative;
}

</style>
